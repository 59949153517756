import React, { useContext, useEffect } from "react";
import VideoActions from "../components/videos/VideoActions";
import VideoPlayer from "../components/videos/VideoPlayer";
import { VideosContext } from "../context/VideosContext";
import VideoInfo from "../components/videos/VideoInfo";
import { PostsContext } from "../context/PostsContext";
import { AuthContext } from "../context/AuthContext";
import Posts from "../components/posts/Posts";
import { Link } from "@reach/router";
import VideoTags from "../components/videos/VideoTags";
import MusclesDiagram from "../components/muscles/MusclesDiagram";

const SingleVideo = ({ video_id }) => {
  const { user } = useContext(AuthContext);
  const { posts, getPosts } = useContext(PostsContext);
  const { video, clearVideo, getSingleVideo } = useContext(VideosContext);

  useEffect(() => {
    getSingleVideo(video_id);
    fetchPosts();
    return clearVideo;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video_id]);

  const fetchPosts = () => getPosts({ video_id });

  const renderVideo = () => {
    if (video && video !== null) {
      return (
        <div className="row">
          <div className="col-12 col-xl-8 pe-md-0">
            <VideoPlayer
              video={video}
              video_id={video_id}
              customer_id={user.customer_id}
            />
          </div>
          <div className="col-12 col-xl-4 bg-light">
            <div className="py-4 px-md-3 px-0 video-info">
              <VideoTags video={video} />
              <h5 className="px-2 mt-4 mb-4">Músculos</h5>
              <MusclesDiagram video_muscles={video.video_muscles} />
            </div>
          </div>
        </div>
      );
    }
  };

  const renderVideoInfo = () => {
    if (video && video !== null) {
      return (
        <div className="container-fluid px-0 px-md-5">
          <VideoInfo video={video} />
        </div>
      );
    }
  };

  return (
    <div className="container-fluid">
      <div className="row py-3 hide-mobile px-4 px-md-5 align-items-center">
        <div className="col-12 col-md-6 px-0">
          <Link to="/mytbm/online" className="btn btn-roundbtn-light mb-3">
            <i className="fa fa-chevron-left me-1"></i>
          </Link>
          <h2 className="d-inline-block text-sentence text-uppercase futura ms-3 mb-0">
            {video && video !== null ? video.name : "Video"}
          </h2>
        </div>
        <div className="col-12 col-md-6 text-right px-0">
          <VideoActions video={video} video_id={video_id} />
        </div>
      </div>
      <div className="show-mobile">
        <div className="row px-3 py-3 align-items-center">
          <div className="container-fluid px-0 mb-2">
            <h2 className="text-sentence text-uppercase futura mb-0">
              {video && video !== null ? video.name : "Video"}
            </h2>
          </div>
          <div className="col-4 px-0">
            <Link
              to="/mytbm/online"
              className="btn btn-light btn-sm shadow-sm border"
            >
              <i className="fa fa-chevron-left me-1"></i> Regresar
            </Link>
          </div>
          <div className="col-8 text-right px-0">
            <VideoActions video={video} video_id={video_id} />
          </div>
        </div>
      </div>
      {renderVideo()}
      {renderVideoInfo()}
      <div className="px-md-5 py-4">
        <Posts
          inputCallback={fetchPosts}
          title="Comentarios"
          id="video-comments"
          video_id={video_id}
          posts={posts}
          hideVideo
        />
      </div>
    </div>
  );
};

export default SingleVideo;
