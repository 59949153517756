import React from "react";
import { getValue } from "../../utils";

const VideoInfo = ({ video }) => {
  const renderProps = () => {
    if (Array.isArray(video.video_props)) {
      if (video.video_props.length === 0) {
        return <span className="small">{video.description}</span>;
      }
      if (window.innerWidth < 767) {
        return (
          <ul className="ps-3">
            {video.video_props.map((video_prop) => (
              <li>
                {video_prop.amount > 1 ? (
                  <span className="bold">
                    {" "}
                    {"("}
                    {video_prop.amount}
                    {")"}{" "}
                  </span>
                ) : (
                  ""
                )}
                {video_prop.prop?.name}
                {getValue(video_prop, "required", "boolean")
                  ? <span className="text-primary bold small ms-2">*Requerido</span>
                  : "(opcional)"}
              </li>
            ))}
          </ul>
        );
      }
      return video.video_props.map((video_prop) => (
        <span
          key={video_prop.prop_id}
          className="badge badge-pill bg-primary me-2 mb-2"
        >
          <span className="d-block">
            {video_prop.amount > 1 ? (
              <span>
                {" "}
                {"("}
                {video_prop.amount}
                {")"}{" "}
              </span>
            ) : (
              ""
            )}
            {video_prop.prop?.name}
          </span>
          <span className="d-block mt-2">
            {getValue(video_prop, "required", "boolean")
              ? "* Requerido"
              : "(opcional)"}
          </span>
        </span>
      ));
    }
  };

  return (
    <div className="card px-3 py-3 py-md-5 border-0 px-md-5 bg-light br-0 shadow no-scale my-3 my-md-0">
      <p className="bold mb-2">Props</p>
      <p className="mb-0">{renderProps()}</p>
    </div>
  );
};
export default VideoInfo;
