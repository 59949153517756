import React from "react";
import moment from "moment";
import VideoSingleTag from "./VideoSingleTag";
import SingleCoach from "../coaches/SingleCoach";

const VideoTags = ({ video }) => {

  const videoDate =
    video.available_at !== null ? video.available_at : video.createdAt;

  return (
    <div className="container-fluid px-2">
      <h5 className="text-primary">
        {moment(videoDate).format("DD MMM YYYY")}
      </h5>
      <SingleCoach size="sm" coach={video.instructor} />
      <VideoSingleTag label="Clase" value={video.class_type?.name} />
      <VideoSingleTag
        label="Categoría"
        value={video.class_type?.class_category?.name}
      />
      <VideoSingleTag label="Impacto" value={video.video_type?.name} />
      <VideoSingleTag label="Duración" value={video.duration} />
      <VideoSingleTag label="Objetivo" value={video.tags[0]?.name} />
    </div>
  );
};

export default VideoTags;
