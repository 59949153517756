import { Link } from "@reach/router";
import React, { useEffect, useContext } from "react";
import { handleWorkoutPlanner } from "../../utils/workout_planner";
import { CustomerWorkoutPlansContext } from "../../context/CustomerWorkoutPlansContext";

const OnlineHeader = ({ title, backUrl, backEnabled }) => {
  const { customer_workout_plan, getActiveCustomerWorkoutPlan } = useContext(
    CustomerWorkoutPlansContext
  );

  useEffect(() => {
    getActiveCustomerWorkoutPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderButton = () => {
    if (customer_workout_plan && customer_workout_plan !== null) {
      return (
        <button
          onClick={() => handleWorkoutPlanner(customer_workout_plan)}
          className="btn btn-sm btn-primary"
        >
          Mi Workout Planner
        </button>
      );
    }
    return (
      <Link to="/pages/workout-planner" className="btn btn-sm btn-primary">
        Workout Planner
      </Link>
    );
  };

  const renderBack = () => {
    if (backEnabled) {
      if (window.history.length > 0) {
        return (
          <button
            onClick={() => window.history.back()}
            className="btn btn-light br-25 d-inline-block me-2 border"
          >
            <i className="fa fa-chevron-left"></i>
            <span className="d-inline d-md-none ms-2 small">Regresar</span>
          </button>
        );
      }
      return (
        <Link
          to={backUrl && backUrl !== null ? backUrl : "/mytbm/online"}
          className="btn btn-light br-25 d-inline-block me-2 border"
        >
          <i className="fa fa-chevron-left"></i>
          <span className="d-inline d-md-none ms-2 small">Regresar</span>
        </Link>
      );
    }
  };

  return (
    <div className="row mx-0 panel-title align-items-center">
      <div className="col-12 col-md-6 mb-3 px-0">
        <div className="d-flex align-items-center">
          <div className="hide-mobile">{renderBack()}</div>
          <h1 className="mb-0 d-inline-block futura text-sentence text-uppercase pt-2">
            {title ? title : "TBM Online"}
          </h1>
        </div>
      </div>
      <div className="col-12 col-md-6 px-0 text-right">
        <div className="row">
          <div className="col-4 show-mobile pe-0">{renderBack()}</div>
          <div className="col-8 col-md-12">{renderButton()}</div>
        </div>
      </div>
    </div>
  );
};

export default OnlineHeader;
